<template>
  <FieldsBase
      class="w-full"
    :name="name"
    :label="label"
    :description="description"
    :required="required"
  >
    <div class="flex gap-2 mt-1 rounded-md shadow-sm w-full">
      <div class="relative w-full grow">
        <input
            :type="type"
            :name="name"
            :id="name"
            :autocomplete="autocomplete"
            :min="min"
            :max="max"
            v-on:input="$emit('update:modelValue', $event.target.value)"
            v-bind:value="modelValue"
            class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 dark:bg-zinc-700"
            :placeholder="placeholder"
            :disabled="disabled"
            v-bind:class="{
              'border-danger-300 text-danger-900 dark:text-red-500 dark:border-danger-600 placeholder-danger-300 focus:ring-danger-500 focus:border-danger-500 ':
            error !== null,
            'border-gray-300 text-gray-900 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500  dark:border-zinc-600 dark:text-gray-300': error === null
        }"
        />
        <div
            v-if="error !== null"
            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <!-- Heroicon name: solid/exclamation-circle -->
          <svg
              class="h-5 w-5 text-danger-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
          >
            <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
<!--      <div>-->
<!--        <slot name="action"></slot>-->
<!--      </div>-->
    </div>
  </FieldsBase>
</template>

<script>
import {useErrorStore} from "~/stores/util/errors";

export default {
  name: "Input",
  props: {
    modelValue: String | Number,
    name: String,
    label: String,
    placeholder: String,
    description: String,
    required: Boolean,
    type: String,
    disabled: Boolean,
    autocomplete: String,
    min: Number,
    max: Number,
  },
  computed: {
    error() {
      const store = useErrorStore();
      return store.getFormError(this.name);
    },
  },
};
</script>
