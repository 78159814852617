<template>
  <div>
    <div class="flex">
      <label
        :for="name"
        class="block text-sm font-medium text-gray-700 dark:text-gray-300 mt-1"
      >
        {{ label }}
      </label>
      <span class="text-red-600 ml-0.5" v-if="required">*</span>
    </div>
    <slot />
    <p v-if="description" class="mt-2 text-sm text-gray-500">
      {{ description }}
    </p>
    <p v-if="error" class="mt-2 text-sm text-red-600 dark:text-red-500">
      {{ error }}
    </p>
  </div>
</template>

<script>
import {useErrorStore} from "~/stores/util/errors";

export default {
  name: "Base",
  props: {
    name: String,
    label: String,
    description: String,
    required: Boolean,
  },
  computed: {
    error() {
      const store = useErrorStore();
      return store.getFormError(this.name);
    },
  },
};
</script>
